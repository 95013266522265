export default {
  ml: 'ml',
  cup: 'puodelis',
  price: 'kaina',
  payByCard: 'mokėti kortele',
  back: 'atgal',
  scanQrCoffeeMachine: 'Prašome nuskaityti QR kodą ant kavos aparato!',
  coffeeMachineOffline: 'Kavos aparatas neprisijungęs, bandykite vėliau.',
  errorNextTry: 'Tinklo klaida, bandome atkurti ryšį, prašome palaukti.',
  fatalError: 'Kritinė tinklo klaida, bandykite vėliau.',
  getCard: 'pridėkite kortelę prie mokėjimo terminalo',
  paymentSuccess: 'mokėjimas sėkmingas',
  paymentError: 'mokėjimo klaida, bandykite dar kartą',
  gettingCup: 'išduodamas puodelis, prašome palaukti',
  preparing: 'ruošiama',
  takeYourDrink: 'paruošta, pasiimkite savo gėrimą',
  beverageNotTaked: 'Pasiimkite savo gėrimą arba jis bus išmestas!',
  beverageDisposal: 'Gėrimo išmetimas',
  busy: 'Kavos aparatas užimtas, prašome palaukti, kol kava bus paruošta.',
  noCupAvailable: 'Pasirinkto puodelio dydžio nėra, prašome pasirinkti kitą gėrimą',
  cupIssueBlocked: 'Išvalykite puodelio išdavimo zoną',
  error: 'Ups... klaida, bandykite vėliau',
  beverageError: 'Kavos aparatas neparuoštas, bandykite vėliau.',
  moneyRefund: 'Įvyko klaida, grąžiname pinigus į kortelę, atsiprašome už nepatogumus',
  takeCup: 'Prašome pasiimti puodelį ir įdėti jį į kavos aparatą.',
  cupNotTaked: 'Prašome pasiimti puodelį ir įdėti jį į kavos aparatą.',
  cupNotTakedStartBeverage: 'Gėrimo ruošimas prasidės po 5 sek.',
}
