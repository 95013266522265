export default {
  ml: 'ml',
  cup: 'krūze',
  price: 'cena',
  payByCard: 'maksāt ar karti',
  back: 'atpakaļ',
  scanQrCoffeeMachine: 'Lūdzu, noskenējiet QR kodu uz kafijas automāta!',
  coffeeMachineOffline: 'Kafijas automāts ir bezsaistē, mēģiniet vēlāk.',
  errorNextTry: 'Tīkla kļūda, mēs mēģinām novērst savienojumu, lūdzu, uzgaidiet.',
  fatalError: 'Fatāla tīkla kļūda, lūdzu, mēģiniet vēlāk.',
  getCard: 'pietuviniet karti pie maksājumu termināļa',
  paymentSuccess: 'maksājums veiksmīgs',
  paymentError: 'maksājuma kļūda, lūdzu, mēģiniet vēlreiz',
  gettingCup: 'izsniedz krūzi, lūdzu, uzgaidiet',
  preparing: 'gatavošana',
  takeYourDrink: 'ir gatavs, paņemiet savu dzērienu',
  beverageNotTaked: 'Paņemiet savu dzērienu, pretējā gadījumā tas tiks iznīcināts!',
  beverageDisposal: 'Dzēriena iznīcināšana',
  busy: 'Kafijas automāts ir aizņemts, lūdzu, uzgaidiet, līdz kafija būs gatava.',
  noCupAvailable: 'Izvēlētā krūzes izmēra nav, lūdzu, izvēlieties citu dzērienu',
  cupIssueBlocked: 'Notīriet krūzes izsniegšanas zonu',
  error: 'Ups... kļūda, mēģiniet vēlāk',
  beverageError: 'Kafijas automāts nav gatavs, lūdzu, mēģiniet vēlāk.',
  moneyRefund:
    'Radusies kļūda, mēs veicam atmaksu uz karti, atvainojamies par sagādātajām neērtībām',
  takeCup: 'Lūdzu, paņemiet krūzi un novietojiet to kafijas automātā.',
  cupNotTaked: 'Lūdzu, paņemiet krūzi un novietojiet to kafijas automātā.',
  cupNotTakedStartBeverage: 'Dzēriena pagatavošana sāksies pēc 5 sekundēm.',
}
